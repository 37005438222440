import React, { useState } from "react"
import { Form, Layout, Input, Button, Row, Col, Select, Typography } from 'antd'
import { endpoints } from "../../lib/endpoint"
import { COUNTRIES } from "../../lib/constants"
import axios from "axios"

const Notice = () => {
    const [submitted, setSubmitted] = useState(false)
    const [submitError, setSubmitError] = useState("")
    const onFinish = (values) => {
        axios.post(`${process.env.REACT_APP_BACKEND_URL}${endpoints.notice}`, { ...values }).then(res => {
            if (res.status === 200) {
                setSubmitted(true)
            } else {
                setSubmitError("Something went wrong. Please try again later.")
            }
        })
    }

    return (
        <Layout >
            <Layout.Header style={{ backgroundColor: "#f0f2f5", display: "flex", justifyContent: "center" }}> Get youself updated with latest working holiday news.</Layout.Header>
            <Layout.Content>
                <Row>
                    <Col span={8} />
                    <Col span={8} style={{ display: "flex", justifyContent: "center" }}>
                        {
                            submitted ? <Typography.Text type="success">Got your contact details, will keep you updated。</Typography.Text> : <Form
                                name="basic"
                                onFinish={onFinish}
                                autoComplete="off"
                            >
                                <Form.Item
                                    label="Name"
                                    name="name"
                                    rules={[{ required: true, message: 'Please enter a name' }]}
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    label="Email"
                                    name="email"
                                    rules={[{ required: true, message: 'Please enter your valid email address', type: "email" }]}
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    label="Mobile"
                                    name="phone"
                                    rules={[{ required: true, message: 'Please enter your mobile number with area code. Eg(+64)', pattern: '^([-]?[+][1-9][0-9]*|0)$' }]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item label="Country/Area" name="country" rules={[{ required: true }]} >
                                    <Select>
                                        {
                                            COUNTRIES.map(c => <Select.Option key={c} value={c}>{c}</Select.Option>)
                                        }
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    noStyle
                                    shouldUpdate={(prevValues, currentValues) => prevValues.country !== currentValues.country}
                                >
                                    {({ getFieldValue }) =>
                                        getFieldValue('country') === 'OTHER' ? (
                                            <Form.Item
                                                label="Specify your country"
                                                name="comments"
                                                rules={[{ required: true, message: 'Please specify your country' }]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        ) : null
                                    }
                                </Form.Item>

                                <Form.Item>
                                    <Button type="primary" htmlType="submit" block >
                                        Subsribe
                                    </Button>
                                </Form.Item>
                                {submitError && <Typography.Text type="danger">{submitError}</Typography.Text>}
                            </Form>
                        }
                    </Col>
                    <Col span={8} />
                </Row>

            </Layout.Content>
            <Layout.Footer style={{ display: "flex", justifyContent: "center" }}>WHVExpert.com</Layout.Footer>
        </Layout>
    )
}

export default Notice